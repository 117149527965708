* {
    margin: 0px;
    /* background-color: #f0f9ff; */
}

html{
    z-index: 5;
    background-color: #f2f2f2;
    font-family: 'Nunito Sans', sans-serif;
}

/* width */
.customScrollerlook ::-webkit-scrollbar{
    width: 5px;
    height: 2px;
  }
  
  /* Track */
  .customScrollerlook ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .customScrollerlook ::-webkit-scrollbar-thumb {
    background: #666666; 
  }

.login_left_bg{
  background: url("./assets/images/XNALabs_Icon_Gradient.svg") no-repeat 0 0;
}